import React from "react"
import styled from "styled-components"
import {
  SectionPage,
  SectionTitle,
  MarkdownContent,
  SectionButtonWrapper,
  Container,
} from "../../components/Section"
import SectionButton from "../../components/Button/SectionButton"
import CheckIcon from "../../components/Icons/CheckIcon"

const OptionsGrid = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: auto;
  margin-top: 39px;
  display: flex;
  align-items: center;
  flex-direction: column;

  & p {
    color: #010920;
    padding: 20px 0 22px;
    text-align: left;
  }
`
const FirstItem = styled.div`
  padding: 90px 80px 82px;
  border-radius: 10px;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
  @media (max-width: 1200px) {
    padding: 50px 30px;
  }
  @media (max-width: 900px) {
    padding: 30px 20px;
    max-width: 500px;
    margin: auto;
  }
`
const Option = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 26px;
  color: #1e4156;
`
class FinanceAvailable extends React.Component {
  render() {
    const { data, modalOpen } = this.props
    return (
      <SectionPage 
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px">
        <Container>
          <SectionTitle textAlign="center">{data.title}</SectionTitle>
          <MarkdownContent
            textAlign="center"
            color="#010920"
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
          <OptionsGrid>
            <FirstItem>
              <img src={data.features[0].image} alt="green sky" />
              <p>{data.features[0].description}</p>
              {data.features[0].options.map((item, i) => (
                <Option key={i}>
                  <CheckIcon /> {item}
                </Option>
              ))}
              <SectionButtonWrapper textAlign="left">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => modalOpen()}
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      modalOpen()
                    }
                  }}
                >
                  <SectionButton>Get Started</SectionButton>
                </div>
              </SectionButtonWrapper>
            </FirstItem>
          </OptionsGrid>
        </Container>
      </SectionPage>
    )
  }
}

export default FinanceAvailable
