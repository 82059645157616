import React from "react"
import styled from "styled-components"
import {
  CustomValidationMessage, 
  RequiredTriangle,
  ControlLabel,
  FormRow,
  FormTwoCol,
  Field,
  StepGrid,
  StepLeftGrid, 
  StepRightGrid
} from "../FormStyle"
import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import StepWhiteBar from "../Icons/StepWhiteBar"
import HalfStepBar from "../Icons/HalfStepBar"

const CustomRequired = styled(CustomValidationMessage)`
  width: fit-content !important;
`

class Step3 extends React.Component {
  render() {
    const {
      employerRequire,
      employerInputPattern,
      employerValue,
      jobTitleRequire,
      jobTitleInputPattern,
      jobTitleValue,
      employerPhoneRequire,
      employerPhoneInputPattern,
      employerPhoneValue,
      salaryRequire,
      salaryInputPattern,
      salaryValue,
      employmentLengthRequire,
      employmentLengthValue,
    } = this.props

    if (this.props.currentStep !== 3) {
      // Prop: The current step
      return null
    }

    return (
      <StepGrid>
        <StepLeftGrid>
          <StepWrapper color="#676B79">
            <div>
              <strong className="step-number" style={{color: "#ED602B"}}>1</strong>
              <span className='step-label' style={{color: "#ED602B"}}>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">2</strong>
              <span className='step-label'>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">3</strong>
              <span className='step-label'>STEP</span>
              <HalfStep><HalfStepBar fill="#ED602B" /></HalfStep>              
              <StepVerticalVar fill="#010920"/>
            </div>
            <div>
              <strong className="step-number">4</strong>
              <span className='step-label'>STEP</span>
            </div>
          </StepWrapper>
        </StepLeftGrid>
        <StepRightGrid>
          <FormRow>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="employer">Employer Name</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="employer"
                  placeholder="Please Enter Your Employer Name"
                  id="employer"
                  onChange={this.props.handleChange}
                  value={employerValue}
                  pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                  required
                />
                <CustomRequired
                  display={employerInputPattern === "" ? "none" : "block"}
                  width="fit-content"
                >
                  <RequiredTriangle />
                  <span>{employerInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={employerRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="job_title">Job Title</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="job_title"
                  placeholder="Please Enter Your Job Title"
                  id="job_title"
                  onChange={this.props.handleChange}
                  value={jobTitleValue}
                  pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                  required
                />
                <CustomRequired
                  display={jobTitleInputPattern === "" ? "none" : "block"}
                  width="fit-content"
                >
                  <RequiredTriangle />
                  <span>{jobTitleInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={jobTitleRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="employer_phone">Employer Phone</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="employer_phone"
                  placeholder="Please Enter Your Employer Phone"
                  id="employer_phone"
                  onChange={this.props.handleChange}
                  value={employerPhoneValue}
                  pattern="^[0-9]{10}$"
                />
                <CustomRequired
                  display={employerPhoneInputPattern === "" ? "none" : "block"}
                  width="fit-content"
                >
                  <RequiredTriangle />
                  <span>{employerPhoneInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={employerPhoneRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="salary">Salary</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="salary"
                  placeholder="Please Enter Your Salary"
                  id="salary"
                  onChange={this.props.handleChange}
                  value={salaryValue}
                  pattern="[0-9]+$"
                />
                <CustomRequired
                  display={salaryInputPattern === "" ? "none" : "block"}
                  width="fit-content"
                >
                  <RequiredTriangle />
                  <span>{salaryInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={salaryRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="employment_length">Employment Length</ControlLabel>
                <select type="text" name="employment_length" id="employment_length" className="form-control bg-transparent" onChange={this.props.handleChange} value={employmentLengthValue}>
                <option value="">-Please Select-</option>
                <option value="Less than 6 months">Less than 6 months</option>
                <option value="6 months - 1 year">6 months - 1 year</option>
                <option value="1 - 2 years">1 - 2 years</option>
                <option value="2 - 3 years">2 - 3 years</option>
                <option value="3 - 4 years">3 - 4 years</option>
                <option value="4 - 5 years">4 - 5 years</option>
                <option value="5 - 10 years">5 - 10 years</option>
                <option value="11 years+">11 years+</option>
              </select>
              <CustomRequired display={employmentLengthRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
            </FormTwoCol>
          </FormRow>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step3
