import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import { Link } from "gatsby"
import { SectionPage, BreadCrumb, SectionTitle, Container } from "../../components/Section"
import {HeroSection, HeroCover, LeftHero, RightHero, HeroPageTitle, HeroDesc, HeroAction, HeroItemAction} from "../../components/Hero"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import GreenSky from "../../images/greensky-icon.png"
import BenefitCarousel from "../../components/BenefitCarousel"
import MVVideoModal from "../../components/MVVideoModal"
import { StaticImage } from "gatsby-plugin-image"


const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  & video {
    width: 100%;
  }
  .play-button {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    translate: trasform(-50%, -50%);
    width: 60px;
    height: 60px;

    &:hover {
      cursor: pointer;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`
const IconWrap = styled.div`
	border-radius: 5px;
	background: rgba(1, 9, 32, 0.05);
	border: 1px solid white;
	padding: 10px 20px 5px;
	display: inline-block;
	vertical-align: middle;
	& img {
		height: 34px;
	}
`
const ApplyButton = styled.button`
	border: 0px;
	background: rgba(255, 255, 255, 0.0001);
	color: #ED602B;
	transition: ease 0.5s;
	& svg {
		margin-left: 10px;
		transform: none !important;
		transition: ease 0.3s;
		width: 25px;
	}
	& :hover {
		cursor: pointer;
		text-decoration: none;
		& svg {
			margin-left: 20px;
		}
	}
`
class FinancingHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
    this.applyClicked = this.applyClicked.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo() {
    this.openVideoModal("https://www.youtube.com/watch?v=n0yrCDwJbho")
  }
  applyClicked() {
    const { applyCallBack } = this.props
    if (applyCallBack) {
      applyCallBack()
    }
  }
  render() {
    const { data, subTitle } = this.props
    return (
      <>
      <HeroSection bg="#f4fbff">
        <MVVideoModal
          isOpen={this.state.isVideoModalOpen}
          onClose={this.closeVideoModal}
        />
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> /{" "}
            <Link to="/buying-guide">Buying Guide</Link> /{" "}
            <span>Financing Process</span>
          </BreadCrumb>
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>{data.title}</HeroPageTitle>
              <HeroDesc
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
              <HeroAction>
                <HeroItemAction><IconWrap><img src={GreenSky} alt="greensky" /></IconWrap></HeroItemAction>
								<HeroItemAction><ApplyButton onClick={this.applyClicked}>Apply Now<ArrowIcon fill="#ED602B" /></ApplyButton></HeroItemAction>
							</HeroAction>
            </LeftHero>
            <RightHero>
              <LazyLoad>
                <VideoWrap>
                  <StaticImage
                    src="../../images/video-poster.png"
                    alt="video poster"
                  />
                  <div
                    className="play-button"
                    onClick={this.openVideoModal}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === "Enter" || e.key === " ") {
                        this.openVideoModal()
                      }
                    }}
                  >
                    <StaticImage src="../../images/play-button.png" alt="play button" />
                  </div>
                </VideoWrap>
              </LazyLoad>
            </RightHero>
          </HeroCover>
        </Container>
      </HeroSection>

      <SectionPage
      ept="0"
      epb="60px"
      xpt="0"
      xpb="40px"
      pt="0"
      pb="30px"
      bg="#f4fbff">
        <Container>
        <SectionTitle textAlign="center" mb="20px">{subTitle}</SectionTitle>
        <BenefitCarousel data={data.features} />
        </Container>
      </SectionPage>
      </>
    )
  }
}

export default FinancingHero
