import React from "react"
import styled from "styled-components"
import {
  CustomValidationMessage, 
  RequiredTriangle,
  ControlLabel,
  FormRow,
  FormCol,
  Field,
  StepGrid,
  StepLeftGrid, 
  StepRightGrid
} from "../FormStyle"
import { Checkbox, HelpText, StepWrapper } from "../Section"
import StepWhiteBar from "../Icons/StepWhiteBar"

const CustomRequired = styled(CustomValidationMessage)`
  width: fit-content !important;
`

class Step4 extends React.Component {
  render() {
    if (this.props.currentStep !== 4) {
      // Prop: The current step
      return null
    }

    return (
      <StepGrid>
        <StepLeftGrid>
          <StepWrapper color="#676B79">
            <div>
              <strong className="step-number" style={{ color: "#ED602B" }}>1</strong>
              <span className='step-label' style={{ color: "#ED602B" }}>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">2</strong>
              <span className='step-label'>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">3</strong>
              <span className='step-label'>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">4</strong>
              <span className='step-label'>STEP</span>
            </div>
          </StepWrapper>
        </StepLeftGrid>
        <StepRightGrid>
          <FormRow>
            <FormCol>
              <Field>
                <ControlLabel htmlFor="order_detail">Order Detail</ControlLabel>
                <textarea
                  class="form-control bg-transparent"
                  name="order_detail"
                  placeholder="Must be more than 500 charactors"
                  id="order_detail"
                  rows="6"
                  onChange={this.props.handleChange}
                />
                <CustomRequired display={this.props.orderDetailRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
            </FormCol>
            <FormCol>
              <Field>
                <Checkbox>
                  <label>
                    <input
                      className="form-control"
                      type="checkbox"
                      id="agreeViaSms"
                      name="agreeViaSms"
                      defaultChecked={true}
                      onChange={this.props.handleChange}
                      aria-label="contacted via SMS"
                    />
                    {` `}I agree that I will be contacted via SMS.
                  </label>
                </Checkbox>
                <HelpText>
                  By submitting this form, I agree to receive text messages from
                  Probuilt Steel Buildings. Standard messaging rates and data
                  charges may apply. Message frequency may vary. You can opt-out by
                  replying STOP at any time or reply Help to get more information.
                </HelpText>
              </Field>
            </FormCol>
          </FormRow>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step4
