import React from "react"
import styled from "styled-components"
import {
  CustomValidationMessage, 
  RequiredTriangle,
  ControlLabel,
  FormRow,
  FormTwoCol,
  Field,
  StepGrid,
  StepLeftGrid, 
  StepRightGrid
} from "../FormStyle"
import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import StepWhiteBar from "../Icons/StepWhiteBar"
import HalfStepBar from "../Icons/HalfStepBar"

const CustomRequired = styled(CustomValidationMessage)`
  width: fit-content !important;
`

class Step2 extends React.Component {
  render() {
    const {
      sAddressRequire,
      sAddressInputPattern,
      sAddressValue,
      cityRequire,
      cityInputPattern,
      cityValue,
      stateRequire,
      stateInputPattern,
      stateValue,
      zipcodeRequire,
      zipCodeInputPattern,
      zipCodeValue,
      ownRentRequire,
      ownRentValue,
      monthlyPaymentRequire,
      monthlyPaymentInputPattern,
      monthlyPaymentValue,
    } = this.props
    if (this.props.currentStep !== 2) {
      // Prop: The current step
      return null
    }
    return (
      <StepGrid>
        <StepLeftGrid>
          <StepWrapper color="#676B79">
            <div>
              <strong className="step-number" style={{color: "#ED602B"}}>1</strong>
              <span className='step-label' style={{color: "#ED602B"}}>STEP</span>
              <StepWhiteBar fill="#ED602B" />
            </div>
            <div>
              <strong className="step-number">2</strong>
              <span className='step-label'>STEP</span>
              <HalfStep><HalfStepBar fill="#ED602B" /></HalfStep>              
              <StepVerticalVar fill="#010920"/>
            </div>
            <div>
              <strong className="step-number">3</strong>
              <span className='step-label'>STEP</span>
              <StepVerticalVar fill="#010920"/>
            </div>
            <div>
              <strong className="step-number">4</strong>
              <span className='step-label'>STEP</span>
            </div>
          </StepWrapper>
        </StepLeftGrid>
        <StepRightGrid>
          <FormRow>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="street_address">Street Address*</ControlLabel>
              <input
                className="form-control bg-transparent"
                type="text"
                name="street_address"
                placeholder="Please Enter Your Street Address"
                id="street_address"
                onChange={this.props.handleChange}
                value={sAddressValue}
                pattern="^[a-zA-Z0-9\s,'-]*$"
                required
              />
              <CustomRequired display={sAddressInputPattern === "" ? "none" : "block"} width="fit-content">
                <RequiredTriangle />
                <span>{sAddressInputPattern}</span>
              </CustomRequired>
              <CustomRequired display={sAddressRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="city">City*</ControlLabel>
              <input
                className="form-control bg-transparent"
                type="text"
                name="city"
                placeholder="Please Enter Your City"
                id="city"
                onChange={this.props.handleChange}
                value={cityValue}
                pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
              />
              <CustomRequired
                display={cityInputPattern === "" ? "none" : "block"}
                width="fit-content"
              >
                <RequiredTriangle />
                <span>{cityInputPattern}</span>
              </CustomRequired>
              <CustomRequired display={cityRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="state">State*</ControlLabel>
              <input
                className="form-control bg-transparent"
                type="text"
                name="state"
                placeholder="Please Enter Your State"
                id="state"
                onChange={this.props.handleChange}
                value={stateValue}
                pattern="[A-Za-z\s]+$"
              />
              <CustomRequired
                display={stateInputPattern === "" ? "none" : "block"}
                width="fit-content"
              >
                <RequiredTriangle />
                <span>{stateInputPattern}</span>
              </CustomRequired>
              <CustomRequired display={stateRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="zipcode">Zip*</ControlLabel>
              <input
                className="form-control bg-transparent"
                type="text"
                name="zipcode"
                placeholder="Please Enter Your ZipCode"
                id="zipcode"
                onChange={this.props.handleChange}
                value={zipCodeValue}
                pattern="^[0-9]{5}$"
              />
              <CustomRequired
                display={zipCodeInputPattern === "" ? "none" : "block"}
                width="fit-content"
              >
                <RequiredTriangle />
                <span>{zipCodeInputPattern}</span>
              </CustomRequired>
              <CustomRequired display={zipcodeRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="own_rent">Own or Rent*</ControlLabel>
              <select  className="form-control bg-transparent" name="own_rent" id="own_rent" onChange={this.props.handleChange} value={ownRentValue}>
                <option value="">-Please Select-</option>
                <option value="own">Own</option>
                <option value="rent">Rent</option>
              </select>
              <CustomRequired display={ownRentRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
            <FormTwoCol>
            <Field>
              <ControlLabel htmlFor="monthlyPayment">Monthly Payment*</ControlLabel>
              <input
                className="form-control bg-transparent"
                type="text"
                name="monthlyPayment"
                placeholder="Monthly Payment"
                id="monthlyPayment"
                onChange={this.props.handleChange}
                value={monthlyPaymentValue}
                pattern="[0-9]+$"
              />
              <CustomRequired
                display={monthlyPaymentInputPattern === "" ? "none" : "block"}
                width="fit-content"
              >
                <RequiredTriangle />
                <span>{monthlyPaymentInputPattern}</span>
              </CustomRequired>
              <CustomRequired display={monthlyPaymentRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
            </FormTwoCol>
          </FormRow>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step2
